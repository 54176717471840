.about_head{
    font-weight: 600;
    font-size: 3rem;
    line-height: 4.0rem;
    letter-spacing: .4rem;
    background-image: linear-gradient(120deg, #6a11cb 0%, #2575fc 50%);
    background-clip: text;
    -webkit-background-clip: text;
    color: hsl(0 0% 100%/0);
    background-size: 5%, 50%;
    animation: animation_text 5s linear infinite ;
}

.breif_para{
    text-align: left !important;
    margin-top: 1rem;
}
.breif_para > p{
    line-height: 1.7rem;
}
.about_main{
    display: grid;
    grid-template-columns: 1fr 1.6fr ;
    grid-row: 1;
    margin: 7%;
}
.para_content{
    margin-bottom: 1.5rem;
    font-weight: 500;
    color: #858080f1;
}
.social_media{
    margin-bottom: 1.5rem;
    height: 2rem;
}
.social_media > a{
    font-size: 1rem;
    margin-top: 1rem;
    border: 1px solid #2575fc;
    border-radius: 12.5%;
    padding: 0.5rem;
    margin-right: 0.5rem;
    background-image: linear-gradient(120deg, #6a11cb 0%, #2575fc 80%);
    color: #fff;
    background-size: 5%, 50%;
    animation: animation_text 1s ease-out infinite ;
}
.social_media > a:hover{
    background-color: #fff;
    background-image:none;
    color: #2575fc !important;
    scale: 1;
}
.social_media :nth-child(1){
    font-weight: 400;
    font-size: 1.2rem;
    margin-top: 1.3rem;
    margin-bottom: -0.5rem;
}

.contact_email :nth-child(1){
    font-weight: 400;
    font-size: 1.2rem;
    margin-top: 0.5rem;
}
.contact_email > a{
    color: #2575fc;
    text-decoration: none !important;
}
@keyframes animation_text {
    to {
        background-size: 100% 100% ;
    }
}

@media only screen and (max-width:430px) and (max-height:932px){
    .about_main{
        grid-template-columns: auto;
        grid-column: 1;
        row-gap: 10px;
    }
  }
  @media screen and (max-width:820px) {
    .about_main{
        grid-template-columns: auto;
        grid-column: 1;
        row-gap: 10px;
    }
  }
  @media screen and (max-width:1024px) {
    .about_main{
        grid-template-columns: auto;
        grid-column: 1;
        row-gap: 1rem;
    }
  }